<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Search Customers</h1>
        <v-form @submit.prevent="getCustomers">
          <v-row class="pt-8">
            <v-col class="col-2">
              <label>Name</label>
              <v-text-field v-model="customerName"></v-text-field>
            </v-col>
            <v-col class="col-2">
              <label>Business Type</label>
              <v-autocomplete
                v-model="type_id"
                :items="businessTypes"
              ></v-autocomplete>
            </v-col>
            <v-col class="col-4">
              <label>Address Line One</label>
              <v-text-field
                v-model="address"
              ></v-text-field>
            </v-col>
            <v-col class="col-2">
              <label>Contact Name</label>
              <v-text-field
                v-model="contactName"
              ></v-text-field>
            </v-col>
            <v-col class="col-2 pt-10">
              <v-btn type="submit">Search</v-btn>
              &nbsp;
              <v-btn @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-simple-table
          v-if="customers.length > 0"
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Postcode</th>
                <th class="text-left">Business Type</th>
                <th class="text-left">Main contact</th>
                <th class="text-left">Tel</th>
                <th class="text-left">Email</th>
                <th class="text-left">Sage Ref</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="customer in customers"
                :key="customer.id"
              >
                <td>
                  <router-link :to="`/customers/view/${customer.id}`">
                    {{ customer.name }}
                  </router-link>
                </td>
                <td>{{ customer.postcode }}</td>
                <td>{{ customer.type }}</td>
                <td>{{ customer.mainContact }}</td>
                <td>{{ customer.tel }}</td>
                <td>{{ customer.email }}</td>
                <td>
                  <span v-if="customer.onSage">
                    <v-icon color="green">mdi-check-bold</v-icon>
                  </span>
                  <span v-else>
                    <v-icon color="red">mdi-close-thick</v-icon>
                  </span>
                </td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/customers/view/${customer.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          v-if="customers.length > 0"
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CustomersIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
        return this.$store.state.userid;
    },
  },
  data() {
    return {
      address: null,
      businessTypes: [],
      contactName: null,
      customerName: '',
      customers: [],
      length: 0,
      page: 1,
      totalVisible: 9,
      type_id: 0,
    };
  },
  watch: {
    page() {
      this.getCustomers();
    },
  },
  methods: {
    setCustomerSearchParams() {
      const params = {};
      params.address = this.address;
      params.contactName = this.contactName;
      params.customerName = this.customerName;
      params.type_id = this.type_id;
      this.$store.commit('setCustomerSearchParams', params);
    },
    getSearchParams() {
      this.address = this.$store.state.customerSearchParams.address;
      this.contactName = this.$store.state.customerSearchParams.contactName;
      this.customerName = this.$store.state.customerSearchParams.customerName;
      this.type_id = this.$store.state.customerSearchParams.type_id;
      this.getCustomers();
    },
    getBusinessTypes() {
      axios.post(`/businessTypes/getAll/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.businessTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearSearch() {
      this.address = '';
      this.contactName = '';
      this.customerName = '';
      this.type_id = null;
      this.getCustomers();
    },
    getCustomers() {
      this.setCustomerSearchParams();
      const postData = {
        name: this.customerName,
        businessType: this.type_id,
        contact: this.contactName,
        address: this.address,
      };
      axios.post(`/customers/getSearched/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.customers = response.data.customers;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSearchParams();
    this.getBusinessTypes();
  },
};
</script>
